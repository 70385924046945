import React, { useContext } from "react"
import { Spring, animated as a } from "@react-spring/web"
import CLink from "./cLink"
import tw from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { GeneralContext } from "../contexts/generalContext"

const BreadCrumb = ({ isMounting, pageContext }) => {
  const lang = pageContext.lang
  const data = useStaticQuery(graphql`
    query breadCrumb {
      allSitePage(filter: { context: { template: { eq: "page_inicio" } } }) {
        nodes {
          context {
            lang
            seo_title
            ruta
          }
        }
      }
    }
  `)

  const isTituloCabeceraBaja = pageContext.template === "page_privada_pedidos"
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  const HomePageContext = data.allSitePage.nodes.find(n => n.context.lang === lang).context
  const homePageTitle = HomePageContext.seo_title
  const headerNoH1 = pageContext.seo_header_no_h1
  const homePageUrl = HomePageContext.ruta
  const parentPage = pageContext.parent_page
  return (
    <Spring
      to={{
        transform: `translateX(${isMounting ? "0rem" : "-10rem"})`,
        opacity: isMounting ? 1 : 0,
      }}
    >
      {styles => (
        <a.div
          style={styles}
          css={tw`pt-4 md:pt-8 text-obmed uppercase text-marron3`}
        >
          <ul
            itemScope
            itemType="https://schema.org/BreadcrumbList"
            css={tw`inline`}
          >
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              position={1}
              css={tw`inline`}
            >
              <CLink
                itemProp="item"
                to={homePageUrl}
                title={homePageTitle}
                css={tw`text-marron3 hover:text-marron2`}
              >
                <span itemProp="name">{t.menu.home}</span>
              </CLink>
            </li>
            {parentPage && (
              <li
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                position={2}
                css={tw`inline`}
              >
                <span> / </span>
                <CLink
                  itemProp="item"
                  to={parentPage.ruta}
                  title={parentPage.title}
                  css={tw`text-marron3 hover:text-marron2`}
                >
                  <span itemProp="name">{parentPage.title}</span>
                </CLink>
              </li>
            )}
          </ul>
          {headerNoH1 ? (
            <span
              css={[
                tw`block pt-2 pb-4 md:pb-10 md:pr-40 text-obh1-m md:text-obh1-t text-gris1 dark:text-gris3`,
                isTituloCabeceraBaja && tw`md:pt-8`,
              ]}
            >
              {pageContext.title}
            </span>
          ) : (
            <h1
              css={[
                tw`block pt-2 pb-4 md:pb-10 md:pr-40 text-obh1-m md:text-obh1-t text-gris1 dark:text-gris3`,
                isTituloCabeceraBaja && tw`md:pt-8`,
              ]}
            >
              {pageContext.title}
            </h1>
          )}
        </a.div>
      )}
    </Spring>
  )
}
export default BreadCrumb
